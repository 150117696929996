
import { companies } from "@/api";
import { Actions, Mutations } from "@/store/models";
import Vue from "vue";

export default Vue.extend({
  name: "ForgotPasswordEmailEntry",
  data() {
    return {
      username: "",
    };
  },
  methods: {
    async submit() {
      try {
        await companies.nlc.sendResetPasswordEmail(this.username);
        this.$store.commit(Mutations.SetToast, {
          text: "Reset Password Email sent successfully.",
        });
        this.$router.push('/')
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      }
    },
  },
});
