
import { companies, setAuthTokenHeader } from "@/api";
import { Actions } from "@/store/models";
import { isValidPassword } from "@/utils";
import Vue from "vue";

export default Vue.extend({
  name: "ForgotPasswordResetPassword",
  props: {
    token: String,
  },
  data() {
    return {
      password: "",
      verifyPassword: "",
    };
  },
  computed: {},
  methods: {
    async submit() {
      if (!this.password || !this.verifyPassword) {
        return;
      }
      this.password = this.password.trim();
      this.verifyPassword = this.verifyPassword.trim();

      if (!isValidPassword(this.password)) {
        this.$store.dispatch(
          Actions.DisplayError,
          `Password validation failed:     
        - Must be at least 8 characters
        - Must contain at least 1 number
        - Must contain at least 1 upper case character
        - Must contain at least 1 lower case character
        - Must contain at least 1 special character`
        );
        return;
      }

      if (this.password !== this.verifyPassword) {
        this.$store.dispatch(Actions.DisplayError, "Password does not match");
        return;
      }

      try {
        setAuthTokenHeader(this.token);
        await companies.nlc.setPassword(this.password);
        this.$router.push("/");
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      }
      this.password = "";
      this.verifyPassword = "";
    },
  },
});
